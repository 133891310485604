
:root {
    --logo-green: #48bb88;
    --paddingMainMobile: 20px;
    --headerMobile: 100px;
    --primary-btn-color: #2185d0;
    --header-bg: rgba(224,225,226,.10980392156862745);
}

body, #root {
    height: 100%;
    font-size: 1rem;
}

/* gray border for cards etc. */
.wrapperWithBorder {
    border: 1px solid #d3d3d37a;
    padding: 20px;
}

#root .wrapperWithBorderRadius {
    border-radius: .28571429rem;
}


.mobile-only {
    display:none;
}

.ui.card, .ui.cards>.card {
    box-shadow: 0 1px 3px 0 #fafafa, 0 0 0 1px #d4d4d5;
}

.ui.teal.button {
   background-color:#48bb88 !important;
}

.app-text-bold {
    font-weight: bold;
}

/* content in the center */
.display-flex-center-x {
    display: flex;
    justify-content: center;
}

.display-flex-center-y {
    display: flex;
    align-items: center;
}

.display-flex-center-both {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* formik error message */
.formik-error-message {
    color: red;
    text-align: left;
    margin-top: 10px;
}

/* Fix of border for confirm buttons which is part of buttons group 
(for example - we have 2 buttons: edit /delete( delete also has 2 buttons - 'confirm' and 'ok'). 
First button (Confirm in this case) should not have border-radius )*/
.buttons .buttons > button:first-of-type{
    border-radius:0 !important;
}

/* button mini-tiny less then tiny, very small */
.ui.tiny-mini {
    padding: 7px 10px;
    font-size: 11px;
}

.user-menu-wrapper {
    width: auto !important;
    left: unset !important;
    right: 0 !important;
}

/* color of header bg, see price config header with dropdown and publish button */
#root .header-bg-color {
    background-color: var(--header-bg);
}

.modals .ui.form .field, .modals .ui.form input[type=text].MuiInputBase-input, .modals .field .text, .modals .validation-error {
    font-size:12px !important;
}
.modals .ui.selection.dropdown {
    padding: 8.1px 12px;
    min-height: auto;
}

/* removes border under the main menu */
.app-menu .item::before {
    top: 0 !important;
}

.sticky {
    position: sticky;
    top: 0;
}

/* */
.ui.selection.dropdown {
    line-height: 14px !important;
    padding-top: 9.5px !important;
    padding-bottom: 9.5px !important;
}

.DayPickerInput-Overlay {
    z-index:1000;
}

@media only screen and (max-width: 768px) {
    .mobile-hidden {
        display:none !important;
    }

    .mobile-only {
        display:flex;
    }

    .footer-modal {
        margin: -14px;
        margin-top: 40px;
    }

    /* fix: semantic ui, makes text of the first cell in the table row - to be bold */
    .ui.table:not(.unstackable) td:first-child, .ui.table:not(.unstackable) th:first-child {
        font-weight: normal;
    }

    .table-cell-content-left-mobile {
        text-align: left;
    }
}




/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .table-cell-content-center-non-mobile {
        text-align: center !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1599px) {
    body *, .app-logo {
        font-size: medium;
    }

    .table-cell-content-center-non-mobile {
        text-align: center !important;
    }
  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
    body *, .app-logo {
        font-size: x-large;
    }

    .table-cell-content-center-non-mobile {
        text-align: center !important;
    }

    .modals .ui.form .field, .modals .ui.form input[type=text].MuiInputBase-input, .modals .field .text, .modals .validation-error {
        font-size:14px !important;
    }

}