.customers-info {
    display:flex;
    flex-direction: column;
}

.customers-info .customer-info-list {
    display:flex;
    flex-direction: row;
    height: 7vw;
    justify-content: space-between;
    min-height:108px;
    max-height: 140px;
}


.customers-info .customers-info-header {
    font-size:1.2rem;
    text-align: center;
    padding-bottom: 10px;
    display:none;
}   

.customer-info-list .customers-info-item, .customer-info-list > .column {
    flex:3;
    border-left: 3px solid transparent;
    padding: 20px !important;
    background-color: whitesmoke;
    display: inline-flex !important;
    flex-direction: column;
    justify-content: space-between;
    border-right: 5px solid white;
}

.customer-info-list .customers-info-total {
    border-left: 3px solid #e91e63;;
}
.customer-info-list .customers-info-month {
    border-left: 3px solid var(--logo-green);
}
.customer-info-list .customers-info-today {
    border-left: 3px solid #2196f3;
}

.customers-info-item-value {
    margin-bottom: 2px;
}

.customer-info-list .customers-info-total .customers-info-item-value {
    font-size: 30px;
    color: #e91e63;
}
.customer-info-list .customers-info-month .customers-info-item-value {
    font-size: 30px;
    color: var(--logo-green);
}
.customer-info-list .customers-info-today .customers-info-item-value {
    font-size: 30px;
    color: #2196f3;
}
 
.customer-info-list .customers-info-item .customers-info-item-label {
    font-size: 16px;
}


@media only screen and (max-width: 768px) {
    .app-dashbord .customers-info .customer-info-list {
        flex-direction: column;
        flex-wrap: nowrap;

    }

    .app-dashbord .customers-info .customer-info-list .customers-info-item {
        margin-bottom:10px;
        min-height: 100px;
    }
}

