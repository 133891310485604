/* Styles for alert from Swal, see utils/alert.ts */

/* alert confirm button custom style */
.confirm-alert-btn {
    background-color: var(--primary-btn-color);
    color: #fff;
    text-shadow: none;
    min-height: 1em;
    outline: 0;
    border: none;
    padding: 10px 15px;
    line-height: 1em;
    font-size: 12px;
    border-radius: .28571429rem;
    box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgb(34 36 38 / 15%) inset;
    cursor: pointer;

}