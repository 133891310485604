@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap');


.main-content {
    margin-left: 260px;
    padding: 10px 60px;
    margin-top:10vh;
}

/* logo */

.app-logo-text {
    width:100%;
    font-size: 2rem !important;
    text-align: left;
    /* font-family: 'Nunito' !important; */
    color: #48bb88;
    font-weight: bold;
    padding-left:10px;
}

/* app menu */

.app-menu {
    box-shadow: 1px 0 20px 0 #3f4d67 !important;
    overflow-x:hidden;
}

.app-menu, .user-menu {
    height: 9vh;
}

.app-menu .app-logo {
    display:flex !important;
    flex-direction: row;
}

.app-menu .app-logo img {
    object-fit: contain; 
}

.menu .item {
    margin-bottom: 6px;
}

.menu .item div {
   font-size:1.2rem;
}

.app-menu .menu .current-menu-item  {
    color: var(--logo-green) !important;
}

.mobile-app-menu {
    position: fixed;
    height: var(--headerMobile);
    background-color: white;
    top: 0;
    left: 0;
    right: 0;
    z-index: 500;
}

/* main content, common styles  */
.main-content-item {
    display: flex;
    flex-direction: column;
}



@media only screen and (max-width: 768px) {
     #root {
         height:auto !important;
     }

    .main-content {
        margin-left: 0px;
        padding: var(--paddingMainMobile);
        height:100vh;
        padding-top: var(--headerMobile);
        margin-top: 0px;
        overflow-y:hidden;
    }

    .main-content > div {
        overflow-y: auto;
        height:100%;
    }

    .app-menu {
        width: 100% !important;
    }

    .breadcrumb {
        text-align: center;
    }

    .app-logo .app-logo-text {
        font-size: 1.3rem !important;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .app-logo .app-logo-text {
        font-size: 1.3rem !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1599px) {
   
    .app-logo > * {
        font-size: large;
    }

    .app-logo .app-logo-text {
        font-size: 1.5rem !important;
    }

    body .main-content * {
        font-size: 1rem;
    }

    .user-menu .user-menu-element {
        font-size: 1rem !important;
    }
  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
   
    .app-logo > * {
        font-size: x-large;
    }

    body .main-content * {
        font-size: medium;
    }

    .user-menu .user-menu-element {
        font-size: 1rem !important;
    }

}

.login-title {
   color:#48bb88;
   font-size:3rem;
   font-weight:bold;
   margin-bottom:30px;
}