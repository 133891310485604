.price-test-cases-main div.price-test-cases-list-wrapper {
    min-height: 50vh;
    padding: 30px 40px;
}

.price-test-case-table tr th, .price-test-case-table tr td {
    text-align: center !important;
    font-size: 12px !important;
}

.price-test-case-table  tr th:first-child, .price-test-case-table tr td:first-child {
    text-align: left !important;
}

.price-test-case-table  tr th:last-child, .price-test-case-table tr td:last-child {
    text-align: right !important;
    
}

.price-test-case-table  tr th.title-th {
    text-align: center !important;
    font-size: 16px !important;
}

/* right part of header */

.price-test-case-btns-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right:10px;
}
.price-test-case-btns-right  label {
    font-size: 14px !important;
}


/* Filters */
.price-test-case-btns-right .ui.price-test-case-filters {
    display: flex;
    margin: 0px;
    padding: 5px 10px;
    align-items: center;
}

/* Region info */
.price-test-cases-main .price-test-cases-list-wrapper div.price-test-case-region-info {
     text-align: left;
     padding: 15px;
     font-size: 16px ;
     font-weight: bold;
     margin-bottom: 5px;
     margin-top: 30px;
     background-color: var(--header-bg);
}



/* Mobile*/
@media only screen and (max-width: 768px) {
    .price-test-cases-main .price-test-case-header {
        flex-direction: column;
        align-items: flex-start;

    }

    .price-test-case-header .price-config-area-picker-wrapper {
        justify-content: flex-start;
        margin-bottom: 20px;
    }

    .price-test-case-table tr th:last-child, .price-test-case-table tr td:last-child {
        text-align: left !important;
    }
    .price-test-case-table tr th, .price-test-case-table tr td {
        text-align: left !important;
    }
    .price-test-case-table .price-test-case-row-data .price-test-case-edit-btns {
        text-align: right !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px){
    .price-test-cases-main .price-test-case-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .price-test-case-header .price-config-area-picker-wrapper {
        justify-content: flex-start;
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1950px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1950px) {

}