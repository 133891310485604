
.leaflet-container {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
}

#map-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    height: 60vh
}

/* fix tooltip style*/
.leaflet-tooltip {
    color: rgb(3, 61, 141) !important;
    font-weight: bolder;
    font-size: .8rem !important;
}

/* remove strange square from icon*/
.leaflet-div-icon {
    background: transparent;
     border: none;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
    body *, .app-logo {
        font-size: x-large;
    }

    .table-cell-content-center-non-mobile {
        text-align: center;
    }

    #map-wrapper {
        height: 70vh
    }
    

}