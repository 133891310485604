@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap);


.booking-filterbar-datetime-filter {
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.app-booking-list-wrapper {
    overflow-y:auto;
    height: 48vh;
}

.app-booking-table-title {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(34,36,38,.1);
}

.app-booking-status {
    text-align: center !important;
}

@media only screen and (max-width: 768px) {

    .main-content > .booking-page {
        overflow-y: hidden;
    }

    .booking-filterbar {
        min-height: auto;
    }

    .booking-filterbar .vertical, .booking-filterbar .divider {
        display:none !important;
    }
    

    .booking-filterbar-datetime-filter {
        -webkit-flex-direction:row;
                flex-direction:row;
    }
    .booking-filterbar-datetime-filter .DayPickerInput {
        width: 40%;
    }

    .booking-filterbar-datetime-filter .DayPickerInput {
        margin-bottom: 10px;
    }

    .app-booking-list-wrapper .app-booking-list .app-booking-table-header {
        height: 0px;
        visibility: hidden;
    }
    .app-booking-list-wrapper .app-booking-list > td {
        margin-bottom: 10px;
    }

    .app-booking-status {
        text-align: left !important;
    }
    .app-booking-list-wrapper tbody tr:first-child {
        margin-top: -20px;
    }
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1599px) {
  
  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
    .app-booking-list-wrapper {
        height: 60vh;
    }
    
}

@media only screen and (max-width: 1300px) {
    .booking-search-fields {
       -webkit-flex-direction: column;
               flex-direction: column;
       -webkit-justify-content: flex-start;
               justify-content: flex-start;
    }
    .booking-search-fields-el {
        margin-left: 0px !important;
        margin-bottom: 5px;
    }
    .booking-search-fields .icon {
        margin-top: 5px;
        margin-bottom:5px;
        place-self: flex-end;
    }

    .DayPickerInput {
        width:100% !important;
        margin-bottom:10px;
    }
    
}

/* Styles for alert from Swal, see utils/alert.ts */

/* alert confirm button custom style */
.confirm-alert-btn {
    background-color: var(--primary-btn-color);
    color: #fff;
    text-shadow: none;
    min-height: 1em;
    outline: 0;
    border: none;
    padding: 10px 15px;
    line-height: 1em;
    font-size: 12px;
    border-radius: .28571429rem;
    box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgb(34 36 38 / 15%) inset;
    cursor: pointer;

}
/* Material UI time picker  */

.MuiInput-underline:before, .MuiInput-underline:after {
    content: "" !important;
    transition: none !important;
    border-bottom: none !important;
}

@media only screen and (min-width: 1600px) {

    /*fixed size so it is the same as other fields*/
    .MuiInputBase-input, .MuiInput-input {
        font-size: x-large !important;
    }
}
.bookings-details {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.bookings-details > div {
    margin-right: 1%;
}

.bookings-details-item  {
    box-shadow: 1px 1px 8px 0px rgba(34, 60, 80, 0.2);
    display: -webkit-flex;
    display: flex;
    border-radius: .28571429rem;
    border: 1px solid whitesmoke

}
.bookings-details-item .content {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    border: none;
    background: 0 0;
    margin: 0;
    padding: 1em 1em;
    box-shadow: none;
    font-size: 1em;
    border-radius: 0;
    overflow:auto;
}

.bookings-details-item .header {
    font-weight: 700;
    margin-top: -.21425em;
    height: 70px;
    background-color: whitesmoke;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

/* booking-element header */

.booking-element-header-text {
    font-size: 1.1rem !important;
    line-height: 1.2rem;
}

.booking-element-header-text i {
    margin-right: 1px !important;
}


.app-bookings-wrapper > .booking-filterbar, .app-bookings-wrapper .ui.placeholder.segment  {
    border:none;
    min-height: 9rem;
    box-shadow: none;
}

.app-booking-details-wrapper .bookings-details .booking-info,  .app-booking-details-wrapper .bookings-details .bookings-details-col-2 {
    width: 19%;
}

.booking-logs {
    width:58%;
}

.booking-logs > div, .bookings-details-col-2 > div {
    width: 100%;
    margin-bottom: 20px;
}

.bookings-details-item {
    border-top: 0px solid #d4eaea;

}

.payments-list, .booking-log-list {
    overflow: auto;
    max-height: 30vh;
}

/* booking details box elements*/

.booking-info-element {
    margin: 1.1rem 0px;
}

.booking-log-list {
    padding: 20px;
}

.app-booking-list > tbody td, .app-booking-list > tbody th {
    font-size: small;
}

/* payments */
.payments-list-table th {
    font-size: 12px !important;
}

.bookings-details>.content>.header:not(.ui) {
    line-height: 2;
}

.dayPickerInput {
    margin: 0;
    max-width: 100%;
    -webkit-flex: 1 0 auto;
            flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    text-align: left;
    line-height: 1.21428571em;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    padding: .67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    border-radius: .28571429rem;
    transition: box-shadow .1s ease,border-color .1s ease;
    box-shadow: none;
}

.bookings-details-item .description {
    padding-bottom: 3px;
}

.paymentNumber {
    word-wrap: break-word;
}

.receipt-link {
    color: #68a37c;
}

@media only screen and (max-width: 768px) {

    .app-booking-details-wrapper .bookings-details .booking-info,  .app-booking-details-wrapper .bookings-details .booking-log,
    .app-booking-details-wrapper .bookings-details .bookings-details-col-2, .booking-logs {
        width: 100%;
        margin:auto;
    }

    .bookings-details {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .bookings-details > div {
         margin: 0px;
    }

    .bookings-details, .bookings-details {
        margin: 0px;
    }

    .booking-log-list {
        padding: 0px;
    }
    
    .app-booking-list-table-header {
        visibility: hidden;
        height: 0px;
    }

    .booking-info-element {
        margin: 1rem 0px;
    }
    .app-booking-details-back-to-list {
        text-align: center;
    }
    .app-booking-details-wrapper .bookings-details-item {
        margin-bottom: 10px !important;
    }

    .payments-list, .booking-log-list {
        max-height: 10000000px;
    }
    
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 1255px) {

    .bookings-details {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        justify-items: center;
    }
    .app-booking-details-wrapper .bookings-details .booking-info, .app-booking-details-wrapper .bookings-details .bookings-details-col-2
    {
        width: 60%;
        margin-left:20%;
    }
    .booking-logs {
        width: 100%;
    }

    .booking-info-element {
        margin: 2rem 0px;
    }
}

@media only screen and (min-width: 1255px) and (max-width: 1399px) {


    .app-booking-details-wrapper .bookings-details .booking-info, .app-booking-details-wrapper .bookings-details .bookings-details-col-2 {
        width: 20%;
    }

    .booking-info-element {
        margin: 1.1rem 0px;
    }

    .app-bookings-wrapper > .booking-filterbar, .app-bookings-wrapper .ui.placeholder.segment {
        min-height: 8rem;
    }

    .ui.icon.header {
        margin: 1rem 0 1rem;
    }

    .ui.vertical.divider, .ui.icon.header {
        font-size: smaller;
    }

    .booking-info-element .label {
        margin-top: 3px !important;
    }

    .booking-log-list td {
        font-size:11px !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1400px) and (max-width: 1899px) {
    .app-booking-details-wrapper .bookings-details .booking-info, .app-booking-details-wrapper .bookings-details .bookings-details-col-2 {
        width: 20%;
    }
    .booking-logs {
        width:68%;
    }

    .booking-info-element {
        margin: 12px 0px;
    }
  
    .bookings-details-item .description {
      padding-bottom: 0px;
    } 

    .booking-info-element .label {
        margin-top: 3px !important;
    }
    .app-bookings-booking td {
        font-size: 10px !important;
    }

    .payment-status-text .label {
        font-size: 11px !important;
    }
   
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1900px) {
  .bookings-details-item .description, .bookings-details-item .summary, .bookings-details-item .description address {
      font-size: 1rem !important;
      line-height: 1.1rem;
  }

  .bookings-details-item .summary {
      padding-bottom: 7px;
  }

  .booking-info-element .label {
    font-size:14px !important;
   }

   .booking-info-element {
        margin: 1.5rem 0px;
    }

    
}


/*  App modal custom styles */

/* It is a problem to use Formik and Semantic Modal together. 
If we want submit button inside of Form (child of Formik Form), then
it is possible to use this class on element with button to fix styles, to make footer looks pretty
*/
.footer-modal {
    text-align: right;
    margin-top: 20px;
    background: #f9fafb;
    padding: 1rem 1rem;
    text-align: right;
    margin: -20px;
    margin-top: 40px;
}
.customers-info {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.customers-info .customer-info-list {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    height: 7vw;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    min-height:108px;
    max-height: 140px;
}


.customers-info .customers-info-header {
    font-size:1.2rem;
    text-align: center;
    padding-bottom: 10px;
    display:none;
}   

.customer-info-list .customers-info-item, .customer-info-list > .column {
    -webkit-flex:3 1;
            flex:3 1;
    border-left: 3px solid transparent;
    padding: 20px !important;
    background-color: whitesmoke;
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-right: 5px solid white;
}

.customer-info-list .customers-info-total {
    border-left: 3px solid #e91e63;;
}
.customer-info-list .customers-info-month {
    border-left: 3px solid var(--logo-green);
}
.customer-info-list .customers-info-today {
    border-left: 3px solid #2196f3;
}

.customers-info-item-value {
    margin-bottom: 2px;
}

.customer-info-list .customers-info-total .customers-info-item-value {
    font-size: 30px;
    color: #e91e63;
}
.customer-info-list .customers-info-month .customers-info-item-value {
    font-size: 30px;
    color: var(--logo-green);
}
.customer-info-list .customers-info-today .customers-info-item-value {
    font-size: 30px;
    color: #2196f3;
}
 
.customer-info-list .customers-info-item .customers-info-item-label {
    font-size: 16px;
}


@media only screen and (max-width: 768px) {
    .app-dashbord .customers-info .customer-info-list {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap;

    }

    .app-dashbord .customers-info .customer-info-list .customers-info-item {
        margin-bottom:10px;
        min-height: 100px;
    }
}


html > body { 
   background-color: rgb(218 218 218);
}

.login-form {
    max-width: 450px;
}

.login-logo {
    width: 5vw;
}

.login-form .button {
    font-size: medium !important;
}


@media only screen and (max-width: 768px) {
    .ui.grid>.login-form {
        width: 80% !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
    .login-form-wrapper .login-form .button {
        font-size: x-large !important;
    }
}


/* country AND REGION */

.tab-page {
    display: -webkit-flex;
    display: flex; 
    padding: 15px 30px;
}

/* country PRICE CONFIG  */

/*      header with publish btns */

.price-config-publish-reset-btns, .price-config-publish-reset-btns {
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    margin-top:20px;
}

/*      cards   */
.price-conf-col {
    margin-right: 10px;
}

/*      header of card with min price */
.country-price-config-main, .region-price-config-main, .driving-area-price-config-main {
    overflow-y: auto;
}

.country-price-config-main-cols .main-config-header, .region-price-config-main-cols .main-config-header, .driving-area-price-config-main-cols .main-config-header {
    text-align: left;
    text-transform: none !important; 
}

.country-price-config-main-col1, .region-price-config-main-col1, 
.region-price-config-main-col2, 
.driving-area-price-config-main-col1, 
.driving-area-price-config-main-col2 {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.country-price-config-el, .region-price-config-el, .driving-area-price-config-el {
    margin-bottom: 10px;
}

.version-info-config .version-info-header, .country-price-config-main-cols .main-config-header, 
.region-price-config-main-cols .main-config-header, .driving-area-price-config-main-cols .main-config-header {
    font-size: medium !important;
}

/*      tables */
.day-type-factors-table {
    min-width: 15vw;
}

.day-time-zones-table > * , .day-type-factors-table > *{
    text-align: center;
}


.mobile-100-table {
    overflow-x:auto;
}

.driving-area-special-locations table {
    max-width: 45vw;
}

.price-test-case-table tr td:last-child.price-test-case-no-draft-no-published {
    text-align: center !important;
}

@media only screen and (max-width: 991px) {

    .price-conf-col {
        margin-right: 0px;
    }

    .country-region-picker {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .country-price-config-main-cols, .region-price-config-main-cols, .driving-area-price-config-main-cols {
        display:-webkit-flex;
        display:flex;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .country-price-config-main-col1, .region-price-config-main-col1, .region-price-config-main-col2, .driving-area-price-config-main-col1, .driving-area-price-config-main-col2 {
        margin: 0px;
        width: 100%;
    }

    /*      main page tables */

    .mobile-100-table .ui.collapsing.table,
    .region-factors-wrapper .ui.collapsing.table,
    .driving-area-factors-wrapper .ui.collapsing.table.table {
        width: 100%;
        text-align: left; 
    }

    .mobile-100-table .ui.collapsing.table td {
        text-align: left;
    }

    /*      publish buttons */
    .price-config-publish-reset-btns {
        -webkit-justify-content: center !important;
                justify-content: center !important;
    }

    .mobile-only {
        margin-bottom:4px;
    }

}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1599px) {
    .country-price-config-main-col1, .region-price-config-main-col1, .region-price-config-main-col2, .driving-area-price-config-main-col1, .driving-area-price-config-main-col2 {
        min-width: 20vw;
    }
    .car-type-factor {
        text-align: center !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1600px) and (max-width: 1949px) {
    .country-price-config-main-col1, .region-price-config-main-col1, .region-price-config-main-col2, .driving-area-price-config-main-col1, .driving-area-price-config-main-col2 {
        min-width: 21vw;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1950px) {
    .tab-page {
        display: -webkit-flex;
        display: flex;
        padding: 40px 50px;
    }

    .country-price-config-main-col1, .region-price-config-main-col2, .driving-area-price-config-main-col2{
        min-width: 12vw;
    }

    /* main page tables */

    .country-price-config-main-col1 .ui.table, .region-price-config-main-col1 .ui.table, .driving-area-price-config-main-col1 .ui.table {
        min-width: 15vw;
    }

    .car-type-factor {
        text-align: center !important;
    }
}
.week-row { 
    display:-webkit-flex; 
    display:flex;
    margin-bottom: 7px;
}

.week-day-text {
    width: 80px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    display:-webkit-flex;
    display:flex;
}

.week-day-text .label{
    font-size: 14px !important;
    height: 27px;
}

.week-day-date {
    width: 40px;
    height: 35px;
    margin-left: 3.5px;
    border-radius: 5px;
    cursor:pointer;
    font-size: 13px !important;
    text-align: center;
    border: 1px solid #c0b6b6;
}

/* Chrome, Safari, Edge, Opera */
.week-day-date::-webkit-outer-spin-button,
.week-day-date::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.week-day-date {
    -moz-appearance: textfield;
}

.week-day-date:hover {
    background-color: #f2f2f2; 
}

.week-day-time-text {
    font-size:10px;
}

.week-day-date-text {
    font-size: 12px !important;
    height: auto;
}

.week-day-date-text:nth-child(odd), .week-day-date:nth-child(odd) {
    background-color:#e7e7e7;
    -webkit-filter:saturate(0.5);
            filter:saturate(0.5)
}

.week-day-date-text:nth-child(even), .week-day-date:nth-child(even) {
    background-color: #f8f8f8;
}
.week-day-date.week-day-time-has-value {
    background-color: #48bb88;
    color:white;
}

.app-week-time-config-wrapper {
    display:-webkit-flex;
    display:flex;
    -webkit-align-items: start;
            align-items: start;
    overflow:auto;
}

.extra-dates {
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 20px;
}

.btns-preorder-allowed-date {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 10px;
    -webkit-justify-content: right;
            justify-content: right;
}

.extra-dates-wrapper {
    max-height:70vh;
    overflow: auto;
}

@media only screen and (min-width: 2000px) {
   .preOrder-info-wrapper {
     margin-right: 10px;
   }
   .weeks-days-wrapper {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-top:0px;
   }
}


@media only screen and (max-width: 2000px) {
    .app-week-time-config-wrapper {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .weekTimePicker {
        overflow:auto;
        max-width: 90vw;
        max-height: 900px;
    }
    .weeks-days-wrapper {
        display:-webkit-flex;
        display:flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        margin-top:30px;
    }

    .week-day-date {
        width: 30px;
        height: auto;
        font-size: 11px !important;
    }
}

@media only screen and (max-width: 1200px) {
    .app-week-time-config-wrapper {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .weekTimePicker {
        max-width: 90vw;
        max-height: 100% !important;
    }
    .weeks-days-wrapper {
        display:-webkit-flex;
        display:flex;
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse;
        margin-top:30px;
        width: 100%;
        overflow: hidden;
    }
}

.leaflet-container {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
}

#map-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    height: 60vh
}

/* fix tooltip style*/
.leaflet-tooltip {
    color: rgb(3, 61, 141) !important;
    font-weight: bolder;
    font-size: .8rem !important;
}

/* remove strange square from icon*/
.leaflet-div-icon {
    background: transparent;
     border: none;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
    body *, .app-logo {
        font-size: x-large;
    }

    .table-cell-content-center-non-mobile {
        text-align: center;
    }

    #map-wrapper {
        height: 70vh
    }
    

}
.price-test-cases-main div.price-test-cases-list-wrapper {
    min-height: 50vh;
    padding: 30px 40px;
}

.price-test-case-table tr th, .price-test-case-table tr td {
    text-align: center !important;
    font-size: 12px !important;
}

.price-test-case-table  tr th:first-child, .price-test-case-table tr td:first-child {
    text-align: left !important;
}

.price-test-case-table  tr th:last-child, .price-test-case-table tr td:last-child {
    text-align: right !important;
    
}

.price-test-case-table  tr th.title-th {
    text-align: center !important;
    font-size: 16px !important;
}

/* right part of header */

.price-test-case-btns-right {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    margin-right:10px;
}
.price-test-case-btns-right  label {
    font-size: 14px !important;
}


/* Filters */
.price-test-case-btns-right .ui.price-test-case-filters {
    display: -webkit-flex;
    display: flex;
    margin: 0px;
    padding: 5px 10px;
    -webkit-align-items: center;
            align-items: center;
}

/* Region info */
.price-test-cases-main .price-test-cases-list-wrapper div.price-test-case-region-info {
     text-align: left;
     padding: 15px;
     font-size: 16px ;
     font-weight: bold;
     margin-bottom: 5px;
     margin-top: 30px;
     background-color: var(--header-bg);
}



/* Mobile*/
@media only screen and (max-width: 768px) {
    .price-test-cases-main .price-test-case-header {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start;
                align-items: flex-start;

    }

    .price-test-case-header .price-config-area-picker-wrapper {
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        margin-bottom: 20px;
    }

    .price-test-case-table tr th:last-child, .price-test-case-table tr td:last-child {
        text-align: left !important;
    }
    .price-test-case-table tr th, .price-test-case-table tr td {
        text-align: left !important;
    }
    .price-test-case-table .price-test-case-row-data .price-test-case-edit-btns {
        text-align: right !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px){
    .price-test-cases-main .price-test-case-header {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }

    .price-test-case-header .price-config-area-picker-wrapper {
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1950px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1950px) {

}

.users-manager {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.user-manager-main {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.user-manager-col-user-editor {
    width: 36%
}

.user-manager-col-user-list {
    overflow-y:auto;
    width:60%;
}

.user-manager-header {
    -webkit-flex:1 1;
            flex:1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;

}


@media only screen and (max-width: 768px) {

    .user-manager-main {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .user-manager-element {
        width: 100%;
    }

    .user-manager-col-user-list {
        max-height: 10000px;
    }
}





/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: var(--paddingMainMobile);
    top: 36px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
.main-content {
    margin-left: 260px;
    padding: 10px 60px;
    margin-top:10vh;
}

/* logo */

.app-logo-text {
    width:100%;
    font-size: 2rem !important;
    text-align: left;
    /* font-family: 'Nunito' !important; */
    color: #48bb88;
    font-weight: bold;
    padding-left:10px;
}

/* app menu */

.app-menu {
    box-shadow: 1px 0 20px 0 #3f4d67 !important;
    overflow-x:hidden;
}

.app-menu, .user-menu {
    height: 9vh;
}

.app-menu .app-logo {
    display:-webkit-flex !important;
    display:flex !important;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.app-menu .app-logo img {
    object-fit: contain; 
}

.menu .item {
    margin-bottom: 6px;
}

.menu .item div {
   font-size:1.2rem;
}

.app-menu .menu .current-menu-item  {
    color: var(--logo-green) !important;
}

.mobile-app-menu {
    position: fixed;
    height: var(--headerMobile);
    background-color: white;
    top: 0;
    left: 0;
    right: 0;
    z-index: 500;
}

/* main content, common styles  */
.main-content-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}



@media only screen and (max-width: 768px) {
     #root {
         height:auto !important;
     }

    .main-content {
        margin-left: 0px;
        padding: var(--paddingMainMobile);
        height:100vh;
        padding-top: var(--headerMobile);
        margin-top: 0px;
        overflow-y:hidden;
    }

    .main-content > div {
        overflow-y: auto;
        height:100%;
    }

    .app-menu {
        width: 100% !important;
    }

    .breadcrumb {
        text-align: center;
    }

    .app-logo .app-logo-text {
        font-size: 1.3rem !important;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .app-logo .app-logo-text {
        font-size: 1.3rem !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1599px) {
   
    .app-logo > * {
        font-size: large;
    }

    .app-logo .app-logo-text {
        font-size: 1.5rem !important;
    }

    body .main-content * {
        font-size: 1rem;
    }

    .user-menu .user-menu-element {
        font-size: 1rem !important;
    }
  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
   
    .app-logo > * {
        font-size: x-large;
    }

    body .main-content * {
        font-size: medium;
    }

    .user-menu .user-menu-element {
        font-size: 1rem !important;
    }

}

.login-title {
   color:#48bb88;
   font-size:3rem;
   font-weight:bold;
   margin-bottom:30px;
}
:root {
    --columnHeight: 45px;
  }

.app-discount-list-wrapper {
    overflow-y: auto;
    height: calc(20*45px);
    height: calc(20*var(--columnHeight)); /* 20 - should be  the same as number or less then number of items requested from server */
}

.app-discount-list-wrapper table td {
    height: 45px;
    height: var(--columnHeight);
    padding: 0px 10px !important;
}

@media only screen and (max-height: 900px) { 
    .app-discount-list-wrapper  {
        height: calc(10*45px);
        height: calc(10*var(--columnHeight)); /* 10 - should be the same as number or less then number of items requested from server */
    }
}

@media only screen and (min-height: 901px) and (max-height: 2200px) { 
    .app-discount-list-wrapper  {
        height: calc(15*45px);
        height: calc(15*var(--columnHeight)); /* 15 - should be the same as number or less then number of items requested from server */
    }
}

.form-part {
    background-color: #e0e1e282;
    padding:10px;
    padding-bottom: 30px;
    border-radius: 4px;
    margin-bottom:5px;
    margin-top:5px;
}

.discount-statuses {
    display:-webkit-flex;
    display:flex;
    margin-bottom: 10px;
}

.row-item {
    width: 50% !important;
}

.discount-editor-form .ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
    opacity: .88 !important;
}

.discounts-table .ui.horizontal.label {
    margin-right:0px;
}

/* fix border header top border*/
.discounts-table {
    border-top: none !important;
}

.discounts-table thead th {
    border-top: 1px solid rgba(34,36,38,.1);
}

/* dropdown on discount page size fix*/
.search-discount-tels .divider {
    margin: 0px !important;
}

.search-discount-tels input {
    margin: 0px !important;
}
.search-discount-tels i.icon {
    font-size: large !important;
}
/* end dropdown on discount page size fix*/
.discount-amount-type i.icon {
    font-size: large !important;
}

.discount-editor-form .ui.dropdown>.dropdown.icon {
    top: 0px !important;
}

.telNumber {
    font-size: 1rem;
}

.discount-draft-status {
    background-color: #fff9ec;
}

.discount-activePub-status {
    background-color: #dee7ef;
}

.discount-inactivePub-status {
    background-color: #f3eeee;
}

@media only screen and (max-width: 768px) {

    .app-discount-table-header {
        height: 0px;
        visibility: hidden;
    }

    .app-discounts-item {
        height:auto !important;
    }

    .table-cell-used {
        -webkit-justify-content: left !important;
                justify-content: left !important;
    }

}

.telLabel {
    display:-webkit-flex;
    display:flex;
    -webkit-align-items: center;
            align-items: center;
}

/* select dropdown */

.css-1jqq78o-placeholder {
    font-size: 1rem;
}
.telLabel > div {
    font-size:1rem;
}

.css-1n6sfyn-MenuList {
    font-size:1rem;
}

.tels-list-option, .tels-list-search-input {
    font-size: 14px !important;
}

.do-with-selected-item-btns {
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: right;
            justify-content: right;
    margin-bottom:5px;
}

.do-with-seleted-item-delete-selected, .do-with-seleted-item-deselect-selected {
    border:none;
    background-color: transparent;
    font-size:1rem;
    color:tomato;
    cursor:pointer;
}

.do-with-seleted-item-deselect-selected {
    color:darkblue;
}



.app-company-list-wrapper {
    overflow-y: auto;
    height: 48vh;
}

.app-comany-table-title {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(34,36,38,.1);
}

.company-form-group {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 15px;
}

.company-form-label {
    width: 150px;
    text-align: right;
    margin-right: 10px;
}

.company-form-input {
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
}



.app-booking-status {
    text-align: center !important;
}

@media only screen and (max-width: 768px) {

    .main-content > .booking-page {
        overflow-y: hidden;
    }

    .booking-filterbar {
        min-height: auto;
    }

        .booking-filterbar .vertical, .booking-filterbar .divider {
            display: none !important;
        }


    .booking-filterbar-datetime-filter {
        -webkit-flex-direction: row;
                flex-direction: row;
    }

        .booking-filterbar-datetime-filter .DayPickerInput {
            width: 40%;
        }

        .booking-filterbar-datetime-filter .DayPickerInput {
            margin-bottom: 10px;
        }

    .app-booking-list-wrapper .app-booking-list .app-booking-table-header {
        height: 0px;
        visibility: hidden;
    }

    .app-booking-list-wrapper .app-booking-list > td {
        margin-bottom: 10px;
    }

    .app-booking-status {
        text-align: left !important;
    }

    .app-booking-list-wrapper tbody tr:first-child {
        margin-top: -20px;
    }
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1599px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
    .app-booking-list-wrapper {
        height: 60vh;
    }
}

@media only screen and (max-width: 1300px) {
    .booking-search-fields {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
    }

    .booking-search-fields-el {
        margin-left: 0px !important;
        margin-bottom: 5px;
    }

    .booking-search-fields .icon {
        margin-top: 5px;
        margin-bottom: 5px;
        place-self: flex-end;
    }

    .DayPickerInput {
        width: 100% !important;
        margin-bottom: 10px;
    }
}


:root {
    --logo-green: #48bb88;
    --paddingMainMobile: 20px;
    --headerMobile: 100px;
    --primary-btn-color: #2185d0;
    --header-bg: rgba(224,225,226,.10980392156862745);
}

body, #root {
    height: 100%;
    font-size: 1rem;
}

/* gray border for cards etc. */
.wrapperWithBorder {
    border: 1px solid #d3d3d37a;
    padding: 20px;
}

#root .wrapperWithBorderRadius {
    border-radius: .28571429rem;
}


.mobile-only {
    display:none;
}

.ui.card, .ui.cards>.card {
    box-shadow: 0 1px 3px 0 #fafafa, 0 0 0 1px #d4d4d5;
}

.ui.teal.button {
   background-color:#48bb88 !important;
}

.app-text-bold {
    font-weight: bold;
}

/* content in the center */
.display-flex-center-x {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.display-flex-center-y {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.display-flex-center-both {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

/* formik error message */
.formik-error-message {
    color: red;
    text-align: left;
    margin-top: 10px;
}

/* Fix of border for confirm buttons which is part of buttons group 
(for example - we have 2 buttons: edit /delete( delete also has 2 buttons - 'confirm' and 'ok'). 
First button (Confirm in this case) should not have border-radius )*/
.buttons .buttons > button:first-of-type{
    border-radius:0 !important;
}

/* button mini-tiny less then tiny, very small */
.ui.tiny-mini {
    padding: 7px 10px;
    font-size: 11px;
}

.user-menu-wrapper {
    width: auto !important;
    left: unset !important;
    right: 0 !important;
}

/* color of header bg, see price config header with dropdown and publish button */
#root .header-bg-color {
    background-color: rgba(224,225,226,.10980392156862745);
    background-color: var(--header-bg);
}

.modals .ui.form .field, .modals .ui.form input[type=text].MuiInputBase-input, .modals .field .text, .modals .validation-error {
    font-size:12px !important;
}
.modals .ui.selection.dropdown {
    padding: 8.1px 12px;
    min-height: auto;
}

/* removes border under the main menu */
.app-menu .item::before {
    top: 0 !important;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

/* */
.ui.selection.dropdown {
    line-height: 14px !important;
    padding-top: 9.5px !important;
    padding-bottom: 9.5px !important;
}

.DayPickerInput-Overlay {
    z-index:1000;
}

@media only screen and (max-width: 768px) {
    .mobile-hidden {
        display:none !important;
    }

    .mobile-only {
        display:-webkit-flex;
        display:flex;
    }

    .footer-modal {
        margin: -14px;
        margin-top: 40px;
    }

    /* fix: semantic ui, makes text of the first cell in the table row - to be bold */
    .ui.table:not(.unstackable) td:first-child, .ui.table:not(.unstackable) th:first-child {
        font-weight: normal;
    }

    .table-cell-content-left-mobile {
        text-align: left;
    }
}




/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .table-cell-content-center-non-mobile {
        text-align: center !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1599px) {
    body *, .app-logo {
        font-size: medium;
    }

    .table-cell-content-center-non-mobile {
        text-align: center !important;
    }
  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
    body *, .app-logo {
        font-size: x-large;
    }

    .table-cell-content-center-non-mobile {
        text-align: center !important;
    }

    .modals .ui.form .field, .modals .ui.form input[type=text].MuiInputBase-input, .modals .field .text, .modals .validation-error {
        font-size:14px !important;
    }

}
