html > body { 
   background-color: rgb(218 218 218);
}

.login-form {
    max-width: 450px;
}

.login-logo {
    width: 5vw;
}

.login-form .button {
    font-size: medium !important;
}


@media only screen and (max-width: 768px) {
    .ui.grid>.login-form {
        width: 80% !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
    .login-form-wrapper .login-form .button {
        font-size: x-large !important;
    }
}

