/* country AND REGION */

.tab-page {
    display: flex; 
    padding: 15px 30px;
}

/* country PRICE CONFIG  */

/*      header with publish btns */

.price-config-publish-reset-btns, .price-config-publish-reset-btns {
    display:flex;
    justify-content: flex-end;
    margin-top:20px;
}

/*      cards   */
.price-conf-col {
    margin-right: 10px;
}

/*      header of card with min price */
.country-price-config-main, .region-price-config-main, .driving-area-price-config-main {
    overflow-y: auto;
}

.country-price-config-main-cols .main-config-header, .region-price-config-main-cols .main-config-header, .driving-area-price-config-main-cols .main-config-header {
    text-align: left;
    text-transform: none !important; 
}

.country-price-config-main-col1, .region-price-config-main-col1, 
.region-price-config-main-col2, 
.driving-area-price-config-main-col1, 
.driving-area-price-config-main-col2 {
    display:flex;
    flex-direction: column;
}

.country-price-config-el, .region-price-config-el, .driving-area-price-config-el {
    margin-bottom: 10px;
}

.version-info-config .version-info-header, .country-price-config-main-cols .main-config-header, 
.region-price-config-main-cols .main-config-header, .driving-area-price-config-main-cols .main-config-header {
    font-size: medium !important;
}

/*      tables */
.day-type-factors-table {
    min-width: 15vw;
}

.day-time-zones-table > * , .day-type-factors-table > *{
    text-align: center;
}


.mobile-100-table {
    overflow-x:auto;
}

.driving-area-special-locations table {
    max-width: 45vw;
}

.price-test-case-table tr td:last-child.price-test-case-no-draft-no-published {
    text-align: center !important;
}

@media only screen and (max-width: 991px) {

    .price-conf-col {
        margin-right: 0px;
    }

    .country-region-picker {
        flex-direction: column;
    }

    .country-price-config-main-cols, .region-price-config-main-cols, .driving-area-price-config-main-cols {
        display:flex;
        flex-direction: column;
    }

    .country-price-config-main-col1, .region-price-config-main-col1, .region-price-config-main-col2, .driving-area-price-config-main-col1, .driving-area-price-config-main-col2 {
        margin: 0px;
        width: 100%;
    }

    /*      main page tables */

    .mobile-100-table .ui.collapsing.table,
    .region-factors-wrapper .ui.collapsing.table,
    .driving-area-factors-wrapper .ui.collapsing.table.table {
        width: 100%;
        text-align: left; 
    }

    .mobile-100-table .ui.collapsing.table td {
        text-align: left;
    }

    /*      publish buttons */
    .price-config-publish-reset-btns {
        justify-content: center !important;
    }

    .mobile-only {
        margin-bottom:4px;
    }

}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1599px) {
    .country-price-config-main-col1, .region-price-config-main-col1, .region-price-config-main-col2, .driving-area-price-config-main-col1, .driving-area-price-config-main-col2 {
        min-width: 20vw;
    }
    .car-type-factor {
        text-align: center !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1600px) and (max-width: 1949px) {
    .country-price-config-main-col1, .region-price-config-main-col1, .region-price-config-main-col2, .driving-area-price-config-main-col1, .driving-area-price-config-main-col2 {
        min-width: 21vw;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1950px) {
    .tab-page {
        display: flex;
        padding: 40px 50px;
    }

    .country-price-config-main-col1, .region-price-config-main-col2, .driving-area-price-config-main-col2{
        min-width: 12vw;
    }

    /* main page tables */

    .country-price-config-main-col1 .ui.table, .region-price-config-main-col1 .ui.table, .driving-area-price-config-main-col1 .ui.table {
        min-width: 15vw;
    }

    .car-type-factor {
        text-align: center !important;
    }
}