.bookings-details {
    display: flex;
    align-items: flex-start;
}

.bookings-details > div {
    margin-right: 1%;
}

.bookings-details-item  {

    -webkit-box-shadow: 1px 1px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 1px 1px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 1px 1px 8px 0px rgba(34, 60, 80, 0.2);
    display: flex;
    border-radius: .28571429rem;
    border: 1px solid whitesmoke

}
.bookings-details-item .content {
    flex-grow: 1;
    border: none;
    background: 0 0;
    margin: 0;
    padding: 1em 1em;
    box-shadow: none;
    font-size: 1em;
    border-radius: 0;
    overflow:auto;
}

.bookings-details-item .header {
    font-weight: 700;
    margin-top: -.21425em;
    height: 70px;
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* booking-element header */

.booking-element-header-text {
    font-size: 1.1rem !important;
    line-height: 1.2rem;
}

.booking-element-header-text i {
    margin-right: 1px !important;
}


.app-bookings-wrapper > .booking-filterbar, .app-bookings-wrapper .ui.placeholder.segment  {
    border:none;
    min-height: 9rem;
    box-shadow: none;
}

.app-booking-details-wrapper .bookings-details .booking-info,  .app-booking-details-wrapper .bookings-details .bookings-details-col-2 {
    width: 19%;
}

.booking-logs {
    width:58%;
}

.booking-logs > div, .bookings-details-col-2 > div {
    width: 100%;
    margin-bottom: 20px;
}

.bookings-details-item {
    border-top: 0px solid #d4eaea;

}

.payments-list, .booking-log-list {
    overflow: auto;
    max-height: 30vh;
}

/* booking details box elements*/

.booking-info-element {
    margin: 1.1rem 0px;
}

.booking-log-list {
    padding: 20px;
}

.app-booking-list > tbody td, .app-booking-list > tbody th {
    font-size: small;
}

/* payments */
.payments-list-table th {
    font-size: 12px !important;
}

.bookings-details>.content>.header:not(.ui) {
    line-height: 2;
}

.dayPickerInput {
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    text-align: left;
    line-height: 1.21428571em;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    padding: .67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    border-radius: .28571429rem;
    transition: box-shadow .1s ease,border-color .1s ease;
    box-shadow: none;
}

.bookings-details-item .description {
    padding-bottom: 3px;
}

.paymentNumber {
    word-wrap: break-word;
}

.receipt-link {
    color: #68a37c;
}

@media only screen and (max-width: 768px) {

    .app-booking-details-wrapper .bookings-details .booking-info,  .app-booking-details-wrapper .bookings-details .booking-log,
    .app-booking-details-wrapper .bookings-details .bookings-details-col-2, .booking-logs {
        width: 100%;
        margin:auto;
    }

    .bookings-details {
        flex-direction: column;
    }

    .bookings-details > div {
         margin: 0px;
    }

    .bookings-details, .bookings-details {
        margin: 0px;
    }

    .booking-log-list {
        padding: 0px;
    }
    
    .app-booking-list-table-header {
        visibility: hidden;
        height: 0px;
    }

    .booking-info-element {
        margin: 1rem 0px;
    }
    .app-booking-details-back-to-list {
        text-align: center;
    }
    .app-booking-details-wrapper .bookings-details-item {
        margin-bottom: 10px !important;
    }

    .payments-list, .booking-log-list {
        max-height: 10000000px;
    }
    
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 1255px) {

    .bookings-details {
        flex-direction: column;
        justify-content: center;
        justify-items: center;
    }
    .app-booking-details-wrapper .bookings-details .booking-info, .app-booking-details-wrapper .bookings-details .bookings-details-col-2
    {
        width: 60%;
        margin-left:20%;
    }
    .booking-logs {
        width: 100%;
    }

    .booking-info-element {
        margin: 2rem 0px;
    }
}

@media only screen and (min-width: 1255px) and (max-width: 1399px) {


    .app-booking-details-wrapper .bookings-details .booking-info, .app-booking-details-wrapper .bookings-details .bookings-details-col-2 {
        width: 20%;
    }

    .booking-info-element {
        margin: 1.1rem 0px;
    }

    .app-bookings-wrapper > .booking-filterbar, .app-bookings-wrapper .ui.placeholder.segment {
        min-height: 8rem;
    }

    .ui.icon.header {
        margin: 1rem 0 1rem;
    }

    .ui.vertical.divider, .ui.icon.header {
        font-size: smaller;
    }

    .booking-info-element .label {
        margin-top: 3px !important;
    }

    .booking-log-list td {
        font-size:11px !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1400px) and (max-width: 1899px) {
    .app-booking-details-wrapper .bookings-details .booking-info, .app-booking-details-wrapper .bookings-details .bookings-details-col-2 {
        width: 20%;
    }
    .booking-logs {
        width:68%;
    }

    .booking-info-element {
        margin: 12px 0px;
    }
  
    .bookings-details-item .description {
      padding-bottom: 0px;
    } 

    .booking-info-element .label {
        margin-top: 3px !important;
    }
    .app-bookings-booking td {
        font-size: 10px !important;
    }

    .payment-status-text .label {
        font-size: 11px !important;
    }
   
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1900px) {
  .bookings-details-item .description, .bookings-details-item .summary, .bookings-details-item .description address {
      font-size: 1rem !important;
      line-height: 1.1rem;
  }

  .bookings-details-item .summary {
      padding-bottom: 7px;
  }

  .booking-info-element .label {
    font-size:14px !important;
   }

   .booking-info-element {
        margin: 1.5rem 0px;
    }

    
}