:root {
    --columnHeight: 45px;
  }

.app-discount-list-wrapper {
    overflow-y: auto;
    height: calc(20*var(--columnHeight)); /* 20 - should be  the same as number or less then number of items requested from server */
}

.app-discount-list-wrapper table td {
    height: var(--columnHeight);
    padding: 0px 10px !important;
}

@media only screen and (max-height: 900px) { 
    .app-discount-list-wrapper  {
        height: calc(10*var(--columnHeight)); /* 10 - should be the same as number or less then number of items requested from server */
    }
}

@media only screen and (min-height: 901px) and (max-height: 2200px) { 
    .app-discount-list-wrapper  {
        height: calc(15*var(--columnHeight)); /* 15 - should be the same as number or less then number of items requested from server */
    }
}

.form-part {
    background-color: #e0e1e282;
    padding:10px;
    padding-bottom: 30px;
    border-radius: 4px;
    margin-bottom:5px;
    margin-top:5px;
}

.discount-statuses {
    display:flex;
    margin-bottom: 10px;
}

.row-item {
    width: 50% !important;
}

.discount-editor-form .ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
    opacity: .88 !important;
}

.discounts-table .ui.horizontal.label {
    margin-right:0px;
}

/* fix border header top border*/
.discounts-table {
    border-top: none !important;
}

.discounts-table thead th {
    border-top: 1px solid rgba(34,36,38,.1);
}

/* dropdown on discount page size fix*/
.search-discount-tels .divider {
    margin: 0px !important;
}

.search-discount-tels input {
    margin: 0px !important;
}
.search-discount-tels i.icon {
    font-size: large !important;
}
/* end dropdown on discount page size fix*/
.discount-amount-type i.icon {
    font-size: large !important;
}

.discount-editor-form .ui.dropdown>.dropdown.icon {
    top: 0px !important;
}

.telNumber {
    font-size: 1rem;
}

.discount-draft-status {
    background-color: #fff9ec;
}

.discount-activePub-status {
    background-color: #dee7ef;
}

.discount-inactivePub-status {
    background-color: #f3eeee;
}

@media only screen and (max-width: 768px) {

    .app-discount-table-header {
        height: 0px;
        visibility: hidden;
    }

    .app-discounts-item {
        height:auto !important;
    }

    .table-cell-used {
        justify-content: left !important;
    }

}

.telLabel {
    display:flex;
    align-items: center;
}

/* select dropdown */

.css-1jqq78o-placeholder {
    font-size: 1rem;
}
.telLabel > div {
    font-size:1rem;
}

.css-1n6sfyn-MenuList {
    font-size:1rem;
}

.tels-list-option, .tels-list-search-input {
    font-size: 14px !important;
}

.do-with-selected-item-btns {
    display:flex;
    justify-content: right;
    margin-bottom:5px;
}

.do-with-seleted-item-delete-selected, .do-with-seleted-item-deselect-selected {
    border:none;
    background-color: transparent;
    font-size:1rem;
    color:tomato;
    cursor:pointer;
}

.do-with-seleted-item-deselect-selected {
    color:darkblue;
}