.week-row { 
    display:flex;
    margin-bottom: 7px;
}

.week-day-text {
    width: 80px;
    justify-content: center;
    align-items: center;
    display:flex;
}

.week-day-text .label{
    font-size: 14px !important;
    height: 27px;
}

.week-day-date {
    width: 40px;
    height: 35px;
    margin-left: 3.5px;
    border-radius: 5px;
    cursor:pointer;
    font-size: 13px !important;
    text-align: center;
    border: 1px solid #c0b6b6;
}

/* Chrome, Safari, Edge, Opera */
.week-day-date::-webkit-outer-spin-button,
.week-day-date::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.week-day-date {
    -moz-appearance: textfield;
}

.week-day-date:hover {
    background-color: #f2f2f2; 
}

.week-day-time-text {
    font-size:10px;
}

.week-day-date-text {
    font-size: 12px !important;
    height: auto;
}

.week-day-date-text:nth-child(odd), .week-day-date:nth-child(odd) {
    background-color:#e7e7e7;
    filter:saturate(0.5)
}

.week-day-date-text:nth-child(even), .week-day-date:nth-child(even) {
    background-color: #f8f8f8;
}
.week-day-date.week-day-time-has-value {
    background-color: #48bb88;
    color:white;
}

.app-week-time-config-wrapper {
    display:flex;
    align-items: start;
    overflow:auto;
}

.extra-dates {
    display:flex;
    justify-content: center;
    margin-top: 20px;
}

.btns-preorder-allowed-date {
    display: flex;
    margin-bottom: 10px;
    justify-content: right;
}

.extra-dates-wrapper {
    max-height:70vh;
    overflow: auto;
}

@media only screen and (min-width: 2000px) {
   .preOrder-info-wrapper {
     margin-right: 10px;
   }
   .weeks-days-wrapper {
    display:flex;
    flex-direction: row;
    margin-top:0px;
   }
}


@media only screen and (max-width: 2000px) {
    .app-week-time-config-wrapper {
        flex-wrap: wrap;
        flex-direction: column;
    }

    .weekTimePicker {
        overflow:auto;
        max-width: 90vw;
        max-height: 900px;
    }
    .weeks-days-wrapper {
        display:flex;
        flex-direction: row;
        margin-top:30px;
    }

    .week-day-date {
        width: 30px;
        height: auto;
        font-size: 11px !important;
    }
}

@media only screen and (max-width: 1200px) {
    .app-week-time-config-wrapper {
        flex-wrap: wrap;
        flex-direction: column;
    }

    .weekTimePicker {
        max-width: 90vw;
        max-height: 100% !important;
    }
    .weeks-days-wrapper {
        display:flex;
        flex-direction: column-reverse;
        margin-top:30px;
        width: 100%;
        overflow: hidden;
    }
}