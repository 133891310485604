
.users-manager {
    display:flex;
    flex-direction: column;
}

.user-manager-main {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
}

.user-manager-col-user-editor {
    width: 36%
}

.user-manager-col-user-list {
    overflow-y:auto;
    width:60%;
}

.user-manager-header {
    flex:1;
    display: flex;
    justify-content: flex-end;

}


@media only screen and (max-width: 768px) {

    .user-manager-main {
        flex-direction: column;
    }

    .user-manager-element {
        width: 100%;
    }

    .user-manager-col-user-list {
        max-height: 10000px;
    }
}


