


.app-company-list-wrapper {
    overflow-y: auto;
    height: 48vh;
}

.app-comany-table-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(34,36,38,.1);
}

.company-form-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.company-form-label {
    width: 150px;
    text-align: right;
    margin-right: 10px;
}

.company-form-input {
    flex: 1;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
}



.app-booking-status {
    text-align: center !important;
}

@media only screen and (max-width: 768px) {

    .main-content > .booking-page {
        overflow-y: hidden;
    }

    .booking-filterbar {
        min-height: auto;
    }

        .booking-filterbar .vertical, .booking-filterbar .divider {
            display: none !important;
        }


    .booking-filterbar-datetime-filter {
        flex-direction: row;
    }

        .booking-filterbar-datetime-filter .DayPickerInput {
            width: 40%;
        }

        .booking-filterbar-datetime-filter .DayPickerInput {
            margin-bottom: 10px;
        }

    .app-booking-list-wrapper .app-booking-list .app-booking-table-header {
        height: 0px;
        visibility: hidden;
    }

    .app-booking-list-wrapper .app-booking-list > td {
        margin-bottom: 10px;
    }

    .app-booking-status {
        text-align: left !important;
    }

    .app-booking-list-wrapper tbody tr:first-child {
        margin-top: -20px;
    }
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1599px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
    .app-booking-list-wrapper {
        height: 60vh;
    }
}

@media only screen and (max-width: 1300px) {
    .booking-search-fields {
        flex-direction: column;
        justify-content: flex-start;
    }

    .booking-search-fields-el {
        margin-left: 0px !important;
        margin-bottom: 5px;
    }

    .booking-search-fields .icon {
        margin-top: 5px;
        margin-bottom: 5px;
        place-self: flex-end;
    }

    .DayPickerInput {
        width: 100% !important;
        margin-bottom: 10px;
    }
}
