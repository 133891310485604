

/*  App modal custom styles */

/* It is a problem to use Formik and Semantic Modal together. 
If we want submit button inside of Form (child of Formik Form), then
it is possible to use this class on element with button to fix styles, to make footer looks pretty
*/
.footer-modal {
    text-align: right;
    margin-top: 20px;
    background: #f9fafb;
    padding: 1rem 1rem;
    text-align: right;
    margin: -20px;
    margin-top: 40px;
}