/* Material UI time picker  */

.MuiInput-underline:before, .MuiInput-underline:after {
    content: "" !important;
    transition: none !important;
    border-bottom: none !important;
}

@media only screen and (min-width: 1600px) {

    /*fixed size so it is the same as other fields*/
    .MuiInputBase-input, .MuiInput-input {
        font-size: x-large !important;
    }
}